import Link from 'next/link';
import type {
  ButtonHTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from 'react';

import {
  ButtonLabel,
  Loader,
  ButtonStyled,
  ButtonVariants,
  ButtonColors,
} from './Button.styles';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Button text label.
   */
  label?: string | ReactElement;
  /**
   * Button disabled style and behavior.
   */
  disabled?: boolean;
  /**
   * Button type.
   */
  type?: 'submit' | 'reset' | 'button' | undefined;
  /**
   * Icon element.
   */
  icon?: React.ReactNode | null;
  /**
   * Position of icon element.
   */
  iconPosition?: 'left' | 'right';
  /**
   * Button loading state.
   */
  isLoading?: boolean;
  /**
   * Button variant.
   */
  variant?: ButtonVariants;
  /**
   * Color color of the button variant
   */
  color?: ButtonColors;
  /**
   * To render button as link.
   */
  href?: string;
  /**
   * To open link in new tab. Works only if href is provided.
   */
  external?: boolean;
  scroll?: boolean;
}

const Button = ({
  children,
  label,
  variant = 'primary',
  color,
  disabled = false,
  type = 'button',
  icon,
  iconPosition = 'left',
  isLoading,
  href,
  external,
  scroll,
  ...otherProps
}: PropsWithChildren<ButtonProps>) => {
  const leftIcon = iconPosition === 'left' && icon;
  const rightIcon = iconPosition === 'right' && icon;

  return (
    <ButtonStyled
      as={href ? (external ? 'a' : Link) : 'button'}
      href={href}
      disabled={disabled || isLoading}
      type={type}
      $variant={variant || 'primary'}
      $color={color || 'primary'}
      target={external ? '_blank' : '_self'}
      scroll={scroll}
      {...otherProps}
    >
      {isLoading && <Loader inline color="currentColor" size="25px" />}
      <>
        {!isLoading && leftIcon}
        <ButtonLabel
          $offsetLeft={!!leftIcon}
          $offsetRight={!!rightIcon}
          className="Wreno_Button--label"
        >
          {children || label}
        </ButtonLabel>
        {!isLoading && rightIcon}
      </>
    </ButtonStyled>
  );
};

export default Button;
